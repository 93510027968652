import { GenericDynamicComponent } from 'src/app/core/classes/DynamicComponent';
import { PageLayoutType } from 'src/app/core/enumerates/page-layout-type.enumerate';

export class ComponentLayoutStoreService {
  private static storeInstance: ComponentLayoutStoreService;
  private layouts: { [id: string]: () => Promise<GenericDynamicComponent> } = {};

  constructor() {
    this.registerCustomComponents();
  }

  static getInstance(): ComponentLayoutStoreService {
    if (!ComponentLayoutStoreService.storeInstance) {
      ComponentLayoutStoreService.storeInstance = new ComponentLayoutStoreService();
    }
    return ComponentLayoutStoreService.storeInstance;
  }

  addLayout(id: PageLayoutType, loadComponentFn: () => any): void {
    if (this.layouts[id]) {
      console.warn(`Registering Page Layout Component with repeated ID: ${id}`);
      return;
    }

    this.layouts[id] = loadComponentFn;
  }

  async getComponent(type: string): Promise<GenericDynamicComponent | undefined> {
    const loadComponentFn = this.layouts[type];
    if (loadComponentFn) {
      const module = await loadComponentFn();
      return module;
    } else {
      return;
    }
  }

  private registerCustomComponents(): void {
    this.addLayout(PageLayoutType.DEFAULT, () =>
      import('../../../pages/default/default.component').then((m) => m.AppPageDefaultComponent),
    );
    this.addLayout(PageLayoutType.BEST_OFFERS, () =>
      import('../../../pages/best-offers-page/best-offers-page.component').then((m) => m.AppBestOffersPageComponent),
    );
    this.addLayout(PageLayoutType.CONTENTFUL_PAGE, () =>
      import('../../../pages/contentful-page/contentful-page.component').then((m) => m.AppContentfulPageComponent),
    );
    this.addLayout(PageLayoutType.BUILDER_SECTION, () =>
      import('../../../pages/default/builder-section/builder-section.component').then(
        (m) => m.AppSectionBuilderContainerComponent,
      ),
    );
    this.addLayout(PageLayoutType.PAGE_CONTAINER, () =>
      import('../../../pages/default/page-container/page-container.component').then((m) => m.AppPageContainerComponent),
    );
    this.addLayout(PageLayoutType.PAGE_TITLE, () =>
      import('../../../pages/default/page-title/page-title.component').then((m) => m.AppPageTitleComponent),
    );
    this.addLayout(PageLayoutType.SUBMENU, () =>
      import('../../../pages/default/submenu/submenu.component').then((m) => m.AppSubMenuComponent),
    );
    this.addLayout(PageLayoutType.PRODUCT_CAROUSEL, () =>
      import('../../../pages/product/product-carousel-container/product-carousel-container.component').then(
        (m) => m.AppProductCarouselContainerComponent,
      ),
    );
    this.addLayout(PageLayoutType.PRODUCT_GRID, () =>
      import('../../../pages/product/product-grid/product-grid.component').then((m) => m.AppProductGridComponent),
    );
    this.addLayout(PageLayoutType.CATEGORY_BANNER, () =>
      import('../../components/builder/category-banner/category-banner.component').then((m) => m.AppCategoryBannerComponent),
    );

    this.addLayout(PageLayoutType.TIMER_BANNER, () =>
      import('../../components/builder/timer/timer.component').then((m) => m.AppTimerComponent),
    );
  }
}
